
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { ElForm } from 'element-ui/types/form'
import { drawPolygon } from '@/utils/formatData'
import { twoDecimal } from '@/utils/validate'
import { PolygonStyle } from '@/types/common'
import { ProjectUser } from '@/types/project'

@Component
export default class AddArea extends Vue {
  private status = ''
  private mouseTool: any = null
  private overlays: Array<any> = []
  private projectUserList: ProjectUser[] = []
  private info: any = {
    areaName: '',
    areaAcreage: '',
    projectId: this.projectId,
    headUserId: '',
    locationList: [],
    conserveLevel: '', // 养护等级
    conserveDesc: '', // 养护标准描述
    conserveElementIds: [], // 养护要素IDS
    conservePointIds: [] // 养护点位IDS
  }

  elementList = []
  facilityList = []

  private rules = {
    areaName: [{ required: true, message: '请输入分区名称', trigger: ['change'] }],
    locationList: [{ required: true, message: '请输入分区范围', trigger: ['change'] }],
    areaAcreage: [{ validator: twoDecimal, trigger: ['blur', 'change'] }]
  }

  private map: AMap.Map | any = null
  private submitShow = false
  private ploygons: Array<AMap.Polygon> = []
  private selectPolygon: { ind: number; polygon: AMap.Polygon | null } = { ind: -1, polygon: null }
  private polygonSelectStyle: PolygonStyle = {
    strokeColor: '#FF402B',
    fillColor: '#FF402B',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.5,
    strokeStyle: 'solid'
  }

  private polygonCurrentStyle: PolygonStyle = {
    strokeColor: '#9D4DFF',
    fillColor: '#9D4DFF',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.5,
    strokeStyle: 'solid',
    cursor: 'pointer',
    zIndex: 1000
  }

  private polygonStyle: PolygonStyle = {
    strokeColor: '#3592FE',
    fillColor: '#3592FE',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.3,
    strokeStyle: 'solid',
    zIndex: 100
  }

  get areaId () {
    return this.$route.query.areaId
  }

  get projectId () {
    return this.$route.query.projectId
  }

  created () {
    this.getProjectUserList()
    this.loadData().then(() => {
      if (this.areaId) {
        this.getDetail()
      }
    })
    this.loadElementList()
    this.loadFacilityList()
  }

  // 获取项目人员列表
  getProjectUserList () {
    this.$axios.get(this.$apis.project.selectYhProjectUserByList, {
      projectId: this.projectId
    }).then((res: ProjectUser[]) => {
      this.projectUserList = res || []
    })
  }

  // 获取电子围栏
  loadData () {
    return new Promise((resolve, reject) => {
      this.$axios.get(this.$apis.project.selectYhProjectMapByProjectId, {
        projectId: this.projectId
      }).then((res) => {
        !this.map && this.loadMap({ latitude: res.latitude, longitude: res.longitude }, res.locationList)
        resolve(res)
      }).catch((res) => {
        reject(res)
      })
    })
  }

  // 巡查要素
  loadElementList () {
    this.$axios.get(this.$apis.patrol.selectElementByList).then(res => {
      this.elementList = res.list || []
    })
  }

  loadFacilityList () {
    this.$axios.get(this.$apis.grid.selectProjectAreaYhPointList, {
      projectId: this.$route.query.projectId
    }).then(res => {
      this.facilityList = res || []
    })
  }

  // 区域详情
  getDetail () {
    this.$axios.get(this.$apis.project.selectYhProjectAreaByProjectAreaId, {
      projectAreaId: this.areaId
    }).then((res) => {
      res.conserveElementIds = res.conserveElementList.map((item: any) => item.obejctId)
      res.conservePointIds = res.conservePointList.map((item: any) => item.obejctId)
      this.info = res
      if (this.map) {
        this.overlays = drawPolygon(this.map, res.locationList, this.polygonStyle, null)
      }
    })
  }

  // 地图初始化
  loadMap (projectInfo: { longitude: number; latitude: number }, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: [
        'AMap.MouseTool'
      ]
    }).then((AMap) => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('container', {
        center: center,
        zoom: 11
      })
      this.map = map
      drawPolygon(this.map, list, null, null)
      this.mouseTool = new AMap.MouseTool(this.map)
      this.mouseTool.on('draw', (e: any) => {
        // 面积计算，清除掉面积为0的非围栏所绘线条，四舍五入，面积小于0.5平方米的也舍弃
        const area = Math.round(AMap.GeometryUtil.ringArea(e.obj.getPath()))
        if (area > 0) {
          this.overlays.push(e.obj)
        } else {
          this.map.remove(e.obj)
          this.$message.warning('当前所画图形面积为0，无法保存。')
        }
      })
    })
  }

  // 选中
  choosePolyon () {
    this.status = 'choose'
    this.mouseTool.close()
    this.overlays.forEach(item => {
      item.on('click', this.polygonClick)
    })
  }

  // 绘制区域围栏
  drawAreaPolyon () {
    this.status = 'draw'
    this.overlays.forEach(item => {
      item.off('click', this.polygonClick)
    })
    this.mouseTool.polygon(this.polygonStyle)
  }

  polygonClick (e: any) {
    if (this.status === 'choose') {
      const ind = this.overlays.findIndex(item => item.contains(e.lnglat))
      if (ind >= 0) {
        this.selectPolygon.polygon && this.selectPolygon.polygon.setOptions(this.polygonStyle)
        this.selectPolygon.ind = ind
        this.selectPolygon.polygon = this.overlays[ind] as AMap.Polygon
        this.selectPolygon.polygon.setOptions(this.polygonSelectStyle)
      }
    }
  }

  // 清除选中的围栏
  clearSelectPolygon () {
    this.status = 'delete'
    if (this.selectPolygon.ind >= 0) {
      this.$confirm('确定要删除选中的围栏？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.status = ''
        this.removePolygon()
      })
    } else {
      this.$message.warning('请先选中要删除的区域围栏！')
    }
  }

  // 从数组中清除选中的电子围栏
  removePolygon () {
    this.$message.success('删除围栏成功')
    this.map && this.map.remove(this.selectPolygon.polygon)
    this.overlays.splice(this.selectPolygon.ind, 1)
    this.selectPolygon = { ind: -1, polygon: null }
  }

  onSubmit () {
    const location: Array<{ latitude: number; longitude: number; fenceCode: number }> = []
    this.overlays.forEach((item: any, index: number) => {
      const path: Array<{ lng: number; lat: number }> = item.getPath()
      path.forEach((cItem: { lng: number; lat: number }) => {
        location.push({
          latitude: cItem.lat,
          longitude: cItem.lng,
          fenceCode: index
        })
      })
    })
    this.info.locationList = location
    ; (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const url = this.areaId ? this.$apis.project.updateYhProjectArea : this.$apis.project.insertYhProjectArea
        this.$axios.post(url, {
          ...this.info
        }).then(() => {
          this.$message({ message: '保存成功', type: 'success' })
          this.$router.back()
        }).catch((err) => {
          this.submitShow = false
          this.$message(err)
        })
      }
    })
  }
}
